import LinedCircle from "public/assets/decorative-patterns/lined-circle.svg"
import DottedCircle from "public/assets/decorative-patterns/dotted-circle.svg"
import DoubleHeading from "./DoubleHeading"

export default function PatternedDiv({ children, heading, heading2, eyebrow }) {
  return (
    <div>
      <div className="container max-w-screen-xl 2xl:max-w-screen-2xl mx-auto pb-16 lg:pt-12 lg:pb-24 2xl:pb-24">
        {(heading || heading2) && (
          <div className="text-center max-w-3xl mx-auto mb-10 lg:mb-16 2xl:mb-20 relative z-10">
            <span className="eyebrow eyebrow-2 text-purple-bright">{eyebrow}</span>
            <h2 className="px-12 font-semibold text-blue">
              {heading}
              {heading2 && <span className="inline-block mx-2">{heading2}</span>}
            </h2>
          </div>
        )}
        <div className="relative">
          {children}
          <LinedCircle className="hidden lg:block absolute -top-16 -left-16 z-0 size-96 stroke-green-bright fill-green-bright" />
          <DottedCircle className="hidden lg:block absolute -bottom-16 -right-16 z-0 size-96" />
        </div>
      </div>
    </div>
  )
}
